<div class="table-container" #table>
  <table [ngClass]="{ 'has-hover': config?.hoverRows }" aria-live="polite">
    <tbody cdkDropList (cdkDropListDropped)="dragItem($event)">
      <ng-container *ngIf="loading">
        <tr>
          <td [attr.colspan]="getSpan() + 1" style="padding: 0">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
          </td>
        </tr>
      </ng-container>
      <tr *ngIf="filter" class="filter-row">
        <td *ngIf="config?.checkboxes"></td>
        <td *ngIf="config?.canReorder"></td>
        <ng-container *ngFor="let col of tableColumns; let $col = index">
          <ng-container *ngIf="showColumn(col)">
            <ng-container *ngIf="col.filterableColumn; else EmptyCell">
              <td>
                <ng-container *ngIf="col.component !== 'MenuComponent'">
                  <ng-container [ngSwitch]="col.component">
                    <ng-container *ngSwitchCase="'IconComponent'">
                      <mat-select
                        *ngIf="col?.icons"
                        [ngClass]="{ 'has-value': getActiveFilterIcons(col).length > 0, 'filter-select': true }"
                        [value]="getActiveFilterIcons(col).length > 0 ? getActiveFilterIcons(col)[0].filterKey : ''"
                      >
                        <mat-select-trigger *ngIf="getActiveFilterIcons(col).length">
                          <mat-chip-listbox>
                            <mat-chip-option [removable]="true" *ngFor="let activeIcon of getActiveFilterIcons(col)">
                              <mat-icon
                                [color]="'accent'"
                                [svgIcon]="(activeIcon?.materialIcon ? '' : activeIcon?.icon) ?? ''"
                                >{{ activeIcon?.materialIcon ? activeIcon?.icon : '' }}</mat-icon
                              >
                              <span class="chip-label">
                                {{ activeIcon?.label }}
                              </span>
                              <mat-icon matChipRemove (click)="setActiveIconFilter(activeIcon.filterKey, false)"
                                >cancel</mat-icon
                              >
                            </mat-chip-option>
                          </mat-chip-listbox>
                        </mat-select-trigger>
                        <ng-container *ngFor="let icon of col?.icons">
                          <mat-option
                            *ngIf="icon.filterable"
                            [value]="icon?.filterKey"
                            (click)="setActiveIconFilter(icon.filterKey, true)"
                            class="filter-option"
                          >
                            <mat-icon [color]="icon?.color" [svgIcon]="icon.materialIcon ? '' : icon.icon">{{
                              icon?.materialIcon ? icon?.icon : ''
                            }}</mat-icon>
                            {{ icon?.label }}
                          </mat-option>
                        </ng-container>
                      </mat-select>
                    </ng-container>
                    <ng-container *ngSwitchDefault>
                      <input
                        type="search"
                        class="filter-input oeo-input"
                        [attr.aria-label]="'ACTIONS.filter' | translate"
                        [(ngModel)]="col.filterValue"
                        (ngModelChange)="useBackendFilter ? filterTableBackend(col.property, $event) : filterTable()"
                      />
                    </ng-container>
                  </ng-container>
                </ng-container>
              </td>
            </ng-container>
          </ng-container>
        </ng-container>
      </tr>
      <ng-container>
        <ng-container *ngFor="let row of displayedData; let $row = index">
          <tr
            (click)="config?.disableRowClick ?? handleRowClick(row, $row)"
            [ngClass]="{
              stripe: row['_stripe'],
              highlight: newItemAdded(row),
              'has-hints': config?.hasHints,
              'list-item': true
            }"
            cdkDrag
            [cdkDragDisabled]="!config?.canReorder"
            data-testid="data-row"
          >
            <td *ngIf="config?.canReorder" cdkDragHandle>
              <span class="material-symbols-outlined drag-handle"> drag_handle </span>
            </td>
            <td *ngIf="config?.radioButtons" [attr.name]="'LABEL.select' | translate">
              <div>
                <span (click)="stopPropagation($event)">
                  <mat-radio-button
                    name="tableRadio"
                    [checked]="row.selected"
                    (change)="onRadioChange(row, $row)"
                    [value]="row.selected"
                    [disabled]="config?.radioDisabled"
                    color="primary"
                  ></mat-radio-button>
                </span>
              </div>
            </td>
            <td *ngIf="config?.checkboxes" class="checkbox" [attr.name]="'LABEL.select' | translate">
              <div>
                <span (click)="stopPropagation($event)">
                  <mat-checkbox
                    color="primary"
                    (change)="onCheckboxChange(row)"
                    [(ngModel)]="row.selected"
                    [disabled]="row['disableDrawing']"
                  ></mat-checkbox>
                </span>
              </div>
            </td>
            <ng-container *ngFor="let col of tableColumns; let $col = index">
              <ng-container *ngIf="showColumn(col)">
                <td
                  [ngClass]="{
                    sortable: col.sortable !== false,
                    'action-menu': col.component === 'MenuComponent',
                    icon: col.component === 'IconComponent',
                    'word-break': col.wordBreak
                  }"
                  [attr.name]="col.header || (col.ariaLabel ?? '') | translate"
                  [attr.data-testid]="'row-' + $row + '-col-' + $col + '-item-' + getData(row, col.property)"
                >
                  <ng-container [ngSwitch]="col.component">
                    <oa-currency
                      *ngSwitchCase="'CurrencyComponent'"
                      [row]="row"
                      [data]="getData(row, col.property)"
                      [column]="col"
                    ></oa-currency>
                    <oa-date
                      *ngSwitchCase="'DateComponent'"
                      [row]="row"
                      [data]="getData(row, col.property)"
                      [column]="col"
                    ></oa-date>

                    <!-- Switch between button and input component -->
                    <ng-container *ngSwitchCase="'InputComponent'">
                      <ng-container *ngIf="!displaySecondaryColumn(col, row); else buttonComponent">
                        <oa-input
                          [row]="row"
                          [product]="row"
                          [data]="getData(row, col.property)"
                          [column]="col"
                        ></oa-input>
                      </ng-container>
                    </ng-container>

                    <ng-container *ngSwitchCase="'ButtonComponent'">
                      <ng-container *ngIf="!displaySecondaryColumn(col, row); else inputComponent">
                        <oa-button [row]="row" [data]="getData(row, col.property)" [column]="col"></oa-button>
                      </ng-container>
                    </ng-container>

                    <ng-container *ngSwitchCase="'DropdownComponent'">
                      <oa-dropdown
                        *ngIf="!displaySecondaryColumn(col, row); else defaultComponent"
                        [row]="row"
                        [data]="getData(row, col.property)"
                        [options]="col.dropdownOptions ?? []"
                        [column]="col"
                      >
                      </oa-dropdown>
                    </ng-container>

                    <ng-template #inputComponent>
                      <oa-input
                        [row]="row"
                        [product]="row"
                        [data]="getData(row, col.property)"
                        [column]="createTableColumn(col)"
                      ></oa-input>
                    </ng-template>
                    <ng-template #buttonComponent>
                      <oa-button
                        [rowIndex]="$row"
                        [row]="row"
                        [data]="getData(row, col.property)"
                        [column]="createTableColumn(col)"
                      ></oa-button>
                    </ng-template>
                    <ng-template #defaultComponent>
                      <oa-default
                        [row]="row"
                        [data]="getData(row, col.property)"
                        [column]="createTableColumn(col)"
                      ></oa-default>
                    </ng-template>

                    <oa-cross-reference-input
                      *ngSwitchCase="'CrossReferenceInputComponent'"
                      [row]="row"
                      [data]="getData(row, col.property)"
                      [column]="col"
                    ></oa-cross-reference-input>
                    <oa-menu
                      *ngSwitchCase="'MenuComponent'"
                      [row]="row"
                      [data]="getData(row, col.property)"
                      [column]="col"
                    ></oa-menu>
                    <oa-icon *ngSwitchCase="'IconComponent'" [row]="row" [column]="col"></oa-icon>
                    <oa-toggle
                      *ngSwitchCase="'ToggleComponent'"
                      [row]="row"
                      [data]="getData(row, col.property)"
                      [column]="col"
                    ></oa-toggle>
                    <oa-default
                      *ngSwitchDefault
                      [row]="row"
                      [data]="getData(row, col.property)"
                      [column]="col"
                    ></oa-default>
                  </ng-container>
                </td>
              </ng-container>
            </ng-container>

            <td *ngIf="config?.expandable">
              <button
                *ngIf="!row['cannotExapand'] && row['_expandable'] !== false"
                mat-icon-button
                (click)="stopPropagation($event); onToggleExpand(row, $row)"
              >
                <mat-icon *ngIf="expanded && expandedRowIndex === $row; else ExpandIcon" svgIcon="arrow_up"></mat-icon>
              </button>
            </td>
          </tr>
          <tr class="expanded-row" *ngIf="expanded && this.expandedRowIndex === $row" [@detailExpand]>
            <td [attr.colspan]="getSpan()">
              <div>
                <ng-container>
                  <ng-content></ng-content>
                </ng-container>
              </div>
            </td>
          </tr>
          <ng-container *ngFor="let col of tableColumns; let $col = index">
            <tr class="expanded-row" *ngIf="col.displayInFullRow && hasData(row, col.property)">
              <td [attr.colspan]="getSpan()" *ngIf="col.dynamicRowComponent">
                <lib-dynamic-row [component]="col.dynamicRowComponent" [data]="row"></lib-dynamic-row>
              </td>
            </tr>
          </ng-container>
        </ng-container>
      </ng-container>
    </tbody>
    <thead #thead>
      <tr>
        <th *ngIf="config?.canReorder"></th>
        <th *ngIf="config?.radioButtons">{{ 'TABLE_HEADERS.select' | translate }}</th>
        <th *ngIf="config?.checkboxes" class="checkbox">
          <div>
            <span (click)="stopPropagation($event)">
              <mat-checkbox
                color="primary"
                (change)="onSelectAll()"
                [(ngModel)]="selectAll"
                [disabled]="config.disableCheckboxes ?? false"
              ></mat-checkbox>
            </span>
          </div>
        </th>
        <ng-container *ngFor="let col of tableColumns">
          <ng-container *ngIf="showColumn(col) && !col.displayInFullRow">
            <th
              [attr.ariaLabel]="col.header || col.ariaLabel"
              [ngClass]="{
                sortable: col.sortable !== false,
                'action-menu': col.component === 'MenuComponent',
                icon: col.component === 'IconComponent'
              }"
              [attr.data-testid]="'th-el'"
            >
              <ng-container *ngIf="col.sortable !== false">
                <button
                  class="sort"
                  [attr.ariaLabel]="getAriaLabel(col)"
                  type="button"
                  (click)="sortColumn(col.sortable, col.property?.toString())"
                  ovtTrack
                  ovtCategory="Table"
                  [ovtProperties]="{ tableName: (config?.name?.toString() | titlecase) }"
                  [ovtAction]="'Sort' + (col.property?.toString() | titlecase)"
                  [attr.data-testid]="'th-btn-' + col.header?.toString() | lowercase"
                >
                  <ng-container
                    *ngTemplateOutlet="
                      ColumnHeader;
                      context: { header: col.header, headerIcon: col?.headerIcon, headerTooltip: col?.tooltip }
                    "
                  ></ng-container>
                  <span
                    class="sort-direction"
                    [ngClass]="{
                      sorted: col.property === activeSort && params.direction,
                      desc: params.direction === 'desc',
                      asc: params.direction === 'asc'
                    }"
                  >
                    <svg viewBox="0 0 10 24">
                      <g class="arrows">
                        <path class="up" d="M7,13.27l5,5l5-5H7z" />
                        <path class="down" d="M17,10.73l-5-5l-5,5H17z" />
                      </g>
                    </svg>
                  </span>
                </button>
              </ng-container>
              <ng-container *ngIf="col.sortable === false">
                <div
                  *ngTemplateOutlet="
                    ColumnHeader;
                    context: {
                      header: col.header,
                      headerIcon: col?.headerIcon,
                      headerColumnSelectionMenu: col?.headerColumnSelectionMenu
                    }
                  "
                ></div>
              </ng-container>
            </th>
          </ng-container>
        </ng-container>

        <th *ngIf="config?.expandable" class="expandable"><span class="sr-only">Expand</span></th>
      </tr>
    </thead>
  </table>
</div>
<div class="pagination" *ngIf="(count > optionsMinVal || (data ?? []).length > optionsMinVal) && pagination">
  <div class="items-per-page padding left s5">
    <span class="label">{{ 'LABEL.itemsPerPage' | translate }}:</span>
    <mat-select
      #pageSizeInput
      data-testid="itemsPerPage"
      (selectionChange)="updatePageLength(pageSizeInput.value)"
      [(ngModel)]="params.pageSize"
    >
      <mat-option
        *ngFor="let p of itemsPerPageOptions; let i = index"
        [attr.data-testid]="'itemsPerPage-option-' + p"
        [value]="p"
        >{{ p }}</mat-option
      >
    </mat-select>
    <span class="page-position">{{ getPagePosition() }}</span>
  </div>
  <div class="pages">
    <span data-testid="pagePosition">{{ getCurrentPage() }}</span>
    <button
      type="button"
      class="oeo-button square"
      [disabled]="page === 1"
      (click)="decrementPage()"
      data-testid="decrementPage"
    >
      <svg [ngClass]="{ disabled: page === 1 }" width="8" height="14" viewBox="0 0 8 14">
        <path
          d="M7.60938 1.10938L6.89062 0.390625L0.640625 6.64062L0.296875 7L0.640625 7.35938L6.89062 13.6094L7.60938 12.8906L1.71875 7L7.60938 1.10938Z"
        />
      </svg>
    </button>
    <mat-select
      #pageNumberInput
      (selectionChange)="updatePage(pageNumberInput.value)"
      [(ngModel)]="page"
      [disabled]="pageNumbers.length < 2"
    >
      <mat-option *ngFor="let p of pageNumbers; let i = index" [value]="p">{{ p }}</mat-option>
    </mat-select>
    <button
      class="oeo-button square"
      (click)="incrementPage()"
      [disabled]="page === totalPages"
      data-testid="incrementPage"
      type="button"
    >
      <svg [ngClass]="{ disabled: page === totalPages }" width="8" height="14" viewBox="0 0 8 14">
        <path
          d="M0.390625 12.8906L1.10938 13.6094L7.35938 7.35938L7.70313 7L7.35938 6.64063L1.10938 0.390625L0.390625 1.10938L6.28125 7L0.390625 12.8906Z"
        />
      </svg>
    </button>
  </div>
</div>

<ng-template #ExpandIcon>
  <mat-icon svgIcon="arrow_down"></mat-icon>
</ng-template>

<ng-template
  #ColumnHeader
  let-headerIcon="headerIcon"
  let-header="header"
  let-showColumnSelectionMenu="headerColumnSelectionMenu"
  let-headerTooltip="headerTooltip"
>
  <mat-icon
    *ngIf="headerIcon && !showColumnSelectionMenu; else HeaderText"
    [matTooltip]="headerIcon?.tooltip | translate"
    matTooltipPosition="above"
    [svgIcon]="headerIcon.material ? '' : headerIcon.icon"
    [ngClass]="headerIcon.class"
  >
    {{ headerIcon?.material ? headerIcon.icon : '' }}
  </mat-icon>

  <ng-template #HeaderText>
    <span *ngIf="!headerIcon" matTooltip="{{ headerTooltip | translate }}">
      {{ header | translate }}
    </span>
    <oa-select-columns
      *ngIf="showColumnSelectionMenu"
      [columns]="displayColumns"
      (displayColumnsUpdated)="onDisplayColumnsUpdated($event)"
    >
    </oa-select-columns>
  </ng-template>
</ng-template>

<ng-template #EmptyCell>
  <td></td>
</ng-template>
