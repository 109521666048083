<ng-container *ngIf="showMessage">
  <div class="message-container">
    <mat-accordion [class]="userMessage?.order">
      <mat-expansion-panel [disabled]="!userMessage?.content" [ngClass]="color">
        <mat-expansion-panel-header class="message-accordian" [ngClass]="color">
          <span class="icon">
            <span class="material-symbols-outlined">{{ userMessage?.icon }}</span>
          </span>
          <div class="content">
            <span class="alert-title">{{ userMessage?.title | translate : { currentYear: currentYear } }}</span>
          </div>
        </mat-expansion-panel-header>
        <div [ngClass]="color">
          <div>{{ userMessage?.content?.firstParagraph | translate : { currentYear: currentYear } }}</div>
          <div class="padding top s4">{{ userMessage?.content?.secondParagraph | translate }}</div>
          <div *ngIf="userMessage?.content?.bulletContent">
            <ul>
              <li *ngFor="let bullet of userMessage?.content?.bulletContent; let i = index">
                {{ bullet | translate }}
              </li>
            </ul>
            <div>{{ userMessage?.content?.lastParagraph | translate }}</div>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</ng-container>
