import { HollowMetalStockProduct } from './hollowMetalStockProduct.model'
import { EstimateProduct, EstimateProductAttachment } from './estimateProduct.model'
import { CrossReferenceProduct } from './crossReferenceProduct.model'
import { DoorMarking, QuantityToOrderProductDoorMarking } from './door-marking'
import { EstimateProductDoorMarking } from './estimate-product-door-marking.model'
export class Product {
  acn?: string
  brand: string
  buyingProgramCategoryName: string
  category: string
  canConfigure: boolean
  canPurchase?: boolean
  disableDrawing?: boolean = false
  cutSheets: [
    {
      cutSheet: string
      description: string
      productCutSheeId: number
      productId: number
    }
  ]
  customNumber?: string
  customPrice?: boolean
  description: string
  dhisequence?: number
  discountType?: DiscountType
  discount?: number
  doorCost?: number
  doorMarkings?: DoorMarking[]
  effectiveTotalPrice: string
  effectiveUnitPrice: string
  estimateProductAttachments?: EstimateProductAttachment[]
  extNetPrice?: number
  finish: string
  handing?: string
  hardwareCost?: number
  hasConfigError?: boolean
  hollowMetalStockProduct?: HollowMetalStockProduct
  id: number
  imageBase64String?: string
  imageLocation?: string
  indirectCost?: number
  isFastTrack?: boolean
  isFrameElevation?: boolean
  isHollowMetalStock?: boolean
  isKeyed: boolean
  isQuantityDivisible?: boolean
  isRestricted?: boolean
  isServiceCenter?: boolean
  isValuePac?: boolean
  keyedQuickShip: boolean
  laborTECost?: number
  listPrice: number
  lineNumber?: number
  manualQuote: boolean
  metalCost?: number
  netPrice: number
  nonDefaultData?: Record<string, Record<string, string>>
  orderMaximumQuantity?: number
  productId: number
  proExpress: boolean
  proExpressSku: string
  quantity: number
  quantityMultiple?: number
  quoteApplied: boolean
  quoteCategoryName: string
  setQuantity: number
  sku?: string
  source?: string
  templateRequired?: boolean
  totalCost?: number
  totalQuantity: number
  isCrossreferenceProduct?: boolean
  midnote?: string
}

export class FlattenedProduct extends Product {
  clbDiscountId?: number
  discount?: number
  duplicate?: boolean
  quantity: number
  estimateProductDoorMarkings: EstimateProductDoorMarking[]
  doorMarkingsDisplayString?: string
  doorMarkingsActualString?: string
  _expandable?: boolean
  estimateProductAttachments?: EstimateProductAttachment[]
  created?: string
  lastModified?: string
  constructor(estimateProduct: EstimateProduct) {
    super()
    Object.assign(this, estimateProduct.product)
    this.discount = estimateProduct.discount
    this.id = estimateProduct.id
    this.extNetPrice = estimateProduct.quantity * estimateProduct.product.netPrice
    this.proExpressSku = estimateProduct.product.keyedQuickShip ? null : estimateProduct.product.sku
    this.quantity = estimateProduct.quantity
    this.sku = this.proExpress ? this.proExpressSku : estimateProduct.product.sku
    this.isQuantityDivisible = this.quantityMultiple ? this.quantity % this.quantityMultiple === 0 : null
    this.estimateProductDoorMarkings = estimateProduct.estimateProductDoorMarkings
    this.estimateProductAttachments = estimateProduct.estimateProductAttachments
    this.created = estimateProduct.created
    this.lastModified = estimateProduct.lastModified
    if (estimateProduct.estimateProductDoorMarkings?.length) {
      let doormarks = ''
      estimateProduct.estimateProductDoorMarkings.forEach((doorMark, i) => {
        if (i < estimateProduct.estimateProductDoorMarkings.length - 1) {
          doormarks += `${doorMark.name}, `
        } else {
          doormarks += `${doorMark.name}`
        }
      })
    }
    this.quoteApplied = this.discountType === DiscountType.Quote
    this.lineNumber = estimateProduct.lineNumber
    this.midnote = estimateProduct.midnote
  }
}
export class ExtendedProduct extends Product {
  discount?: number
  clbDiscountId?: number
}

export class FlattenedCrossReferenceProduct extends Product {
  duplicate?: boolean
  discount?: number
  constructor(crossReferenceProduct: CrossReferenceProduct) {
    super()
    this.acn = crossReferenceProduct.product.acn
    this.hollowMetalStockProduct = crossReferenceProduct.product.hollowMetalStockProduct
    this.brand = crossReferenceProduct.product.brand
    this.canPurchase = crossReferenceProduct.product.canPurchase
    this.customNumber = crossReferenceProduct.customNumber
    this.category = crossReferenceProduct.product.category
    this.cutSheets = crossReferenceProduct.product.cutSheets
    this.description = crossReferenceProduct.product.description
    this.finish = crossReferenceProduct.product.finish
    this.handing = crossReferenceProduct.product.handing
    this.id = crossReferenceProduct.id
    this.isServiceCenter = crossReferenceProduct.product.isServiceCenter
    this.keyedQuickShip = crossReferenceProduct.product.keyedQuickShip
    this.listPrice = crossReferenceProduct.product.listPrice
    this.productId = crossReferenceProduct.product.productId
    this.netPrice = crossReferenceProduct.product.netPrice
    this.proExpress = crossReferenceProduct.product.proExpress
    this.proExpressSku = crossReferenceProduct.product.keyedQuickShip ? null : crossReferenceProduct.product.sku
    this.sku = this.proExpress ? this.proExpressSku : crossReferenceProduct.product.sku
    this.orderMaximumQuantity = crossReferenceProduct.product.orderMaximumQuantity
    this.isFastTrack = crossReferenceProduct.product.isFastTrack
    this.discountType = crossReferenceProduct.product?.discountType
    this.quoteApplied = this.discountType === DiscountType.Quote
    this.source = crossReferenceProduct.product.source
    this.canConfigure = crossReferenceProduct.product.canConfigure
    this.hasConfigError = crossReferenceProduct.product.hasConfigError
    this.discount = crossReferenceProduct.product.discount
    this.isRestricted = crossReferenceProduct.product.isRestricted
  }
}

export interface ExpandedFlattenedProduct extends FlattenedProduct {
  marks_disabled?: boolean
}

export class QuantityFlattenedProduct extends FlattenedProduct {
  quantity: number
  quantityToOrder: number
  preQuantity: number
  selected: boolean
  quantityToOrderDoorMarks: QuantityToOrderProductDoorMarking[]
}

export interface ExpandedQuantityFlattenedProduct extends QuantityFlattenedProduct {
  marks_disabled?: boolean
}

export enum DiscountType {
  BuyingProgram = 'BP',
  Quote = 'Q',
  UserDefinedDiscount = 'U'
}
