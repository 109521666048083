import { Component, Input } from '@angular/core'
import { MatButtonModule } from '@angular/material/button'
import { TranslateModule } from '@ngx-translate/core'
import { MatIconModule } from '@angular/material/icon'
import { UserMessage } from '@oeo/common'
import { MatExpansionModule } from '@angular/material/expansion'
import { CommonModule } from '@angular/common'
import { MatSnackBarModule } from '@angular/material/snack-bar'

type Color = 'red' | 'yellow'

@Component({
  selector: 'oeo-user-message',
  templateUrl: './user-message.component.html',
  styleUrls: ['./user-message.component.scss'],
  standalone: true,

  imports: [MatButtonModule, TranslateModule, MatIconModule, MatExpansionModule, CommonModule, MatSnackBarModule]
})
export class UserMessageComponent {
  @Input() userMessage?: UserMessage
  @Input() color?: Color = 'red'
  @Input() showMessage?: boolean
  currentYear = new Date().getFullYear()

  alertIcons = {
    error: 'error',
    success: 'check_circle',
    warning: 'warning',
    info: 'info'
  }

  constructor() {}
}
